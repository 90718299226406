import React from 'react';
import { ConnectorCategory } from 'api';
import { colors } from '@plugsurfing/elemental-ui';

export const ConnectorIcon = ({ category }: { category: ConnectorCategory }) => {
  switch (category) {
    case ConnectorCategory.CHADEMO:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 36 36">
          <path
            fill={colors.inkGrey}
            fillRule="evenodd"
            d="M26.382 24.572c-.81 0-1.583-.153-2.3-.419.343.8.535 1.68.535 2.605A6.625 6.625 0 0 1 18 33.376a6.625 6.625 0 0 1-6.618-6.618c0-.925.192-1.805.536-2.604a6.589 6.589 0 0 1-2.3.418 6.617 6.617 0 1 1 0-13.235c.794 0 1.551.147 2.257.404a6.583 6.583 0 0 1-.493-2.5A6.625 6.625 0 0 1 18 2.625a6.625 6.625 0 0 1 6.617 6.618c0 .884-.177 1.727-.493 2.5a6.584 6.584 0 0 1 2.258-.405 6.618 6.618 0 0 1 0 13.235zM18 0C8.059 0 0 8.059 0 18c0 9.94 8.059 18 18 18 9.94 0 18-8.06 18-18 0-9.941-8.06-18-18-18zm2.677 10.672l-1.248 1.247L18 10.489l-1.429 1.43-1.248-1.247 1.43-1.43-1.43-1.429 1.248-1.247L18 7.995l1.429-1.43 1.248 1.248-1.43 1.43 1.43 1.429zM18 4.389a4.86 4.86 0 0 0-4.853 4.853A4.86 4.86 0 0 0 18 14.095a4.86 4.86 0 0 0 4.853-4.853A4.86 4.86 0 0 0 18 4.389zm0 15.751c.808 0 1.581.153 2.299.42a6.598 6.598 0 0 1-.042-5.105 6.57 6.57 0 0 1-2.257.404 6.57 6.57 0 0 1-2.257-.404 6.593 6.593 0 0 1-.042 5.105A6.567 6.567 0 0 1 18 20.14zm2.677 8.046l-1.248 1.249L18 28.005l-1.429 1.43-1.248-1.249 1.43-1.429-1.43-1.429 1.248-1.247L18 25.51l1.429-1.43 1.248 1.248-1.43 1.43 1.43 1.428zM18 21.905a4.86 4.86 0 0 0-4.853 4.853A4.86 4.86 0 0 0 18 31.61a4.86 4.86 0 0 0 4.853-4.853A4.86 4.86 0 0 0 18 21.905z"
          />
        </svg>
      );
    case ConnectorCategory.CCS:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 36">
          <path
            fill={colors.inkGrey}
            fillRule="evenodd"
            d="M2.376 9.758c0-1.785 1.412-3.231 3.153-3.231 1.742 0 3.154 1.446 3.154 3.23 0 1.785-1.412 3.232-3.154 3.232-1.741 0-3.153-1.447-3.153-3.231m6.889-8.252c1.126 0 2.037.935 2.037 2.088S10.391 5.68 9.265 5.68c-1.125 0-2.038-.934-2.038-2.087s.913-2.088 2.038-2.088m6.889 8.252c0 1.784-1.412 3.23-3.154 3.23-1.741 0-3.153-1.446-3.153-3.23 0-1.785 1.412-3.231 3.153-3.231 1.742 0 3.154 1.446 3.154 3.23m.582-8.301c1.125 0 2.037.935 2.037 2.088s-.912 2.088-2.037 2.088-2.038-.935-2.038-2.088.913-2.088 2.038-2.088m6.889 8.302c0 1.784-1.412 3.23-3.154 3.23-1.741 0-3.153-1.446-3.153-3.23 0-1.785 1.412-3.231 3.153-3.231 1.742 0 3.154 1.446 3.154 3.23m-6.89 9.694c-1.74 0-3.152-1.446-3.152-3.231 0-1.784 1.412-3.231 3.153-3.231s3.153 1.447 3.153 3.23c0 1.786-1.412 3.232-3.153 3.232m-7.471 0c-1.742 0-3.153-1.446-3.153-3.231 0-1.784 1.411-3.231 3.153-3.231s3.153 1.447 3.153 3.23c0 1.786-1.411 3.232-3.153 3.232M13 22.24c7.18 0 13-5.964 13-13.321 0-3.43-1.265-6.556-3.343-8.918H3.345A13.459 13.459 0 0 0 0 8.918c0 7.357 5.82 13.321 13 13.321m6.5 11.763c-2.512 0-4.55-2.087-4.55-4.662 0-2.575 2.038-4.662 4.55-4.662 2.513 0 4.55 2.087 4.55 4.662 0 2.575-2.037 4.662-4.55 4.662m-13 0c-2.512 0-4.55-2.087-4.55-4.662 0-2.575 2.038-4.662 4.55-4.662 2.513 0 4.55 2.087 4.55 4.662 0 2.575-2.037 4.662-4.55 4.662m13-11.322h-13c-3.575 0-6.5 2.997-6.5 6.66C0 33.003 2.925 36 6.5 36h13c3.575 0 6.5-2.997 6.5-6.66 0-3.663-2.925-6.66-6.5-6.66"
          />
        </svg>
      );
    case ConnectorCategory.DOMESTIC:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 36 36">
          <path
            fill={colors.inkGrey}
            fillRule="evenodd"
            d="M26.618 20.821a2.821 2.821 0 1 1 0-5.642 2.821 2.821 0 0 1 0 5.642m-17.236 0a2.821 2.821 0 1 1 0-5.643 2.821 2.821 0 0 1 0 5.643m26.375-5.643h-2.345V8.737C30.262 3.508 24.548 0 18 0S5.737 3.508 2.587 8.737v6.441H.243A18.061 18.061 0 0 0 0 18c0 .962.097 1.9.243 2.821h2.344v6.442C5.737 32.49 11.452 36 18 36s12.263-3.509 15.412-8.737V20.82h2.345C35.902 19.9 36 18.962 36 18c0-.963-.098-1.9-.243-2.822"
          />
        </svg>
      );
    case ConnectorCategory.TYPE1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 36 36">
          <path
            fill={colors.inkGrey}
            fillRule="evenodd"
            d="M29.318 25.027a2.821 2.821 0 1 1 0-5.642 2.821 2.821 0 0 1 0 5.642m-8.994-15.15a4.05 4.05 0 1 1 8.1.001 4.05 4.05 0 0 1-8.1 0M18 31.5a4.05 4.05 0 1 1 0-8.099 4.05 4.05 0 0 1 0 8.099M7.576 9.878a4.05 4.05 0 1 1 8.1 0 4.05 4.05 0 0 1-8.1 0m-.894 15.15a2.821 2.821 0 1 1 0-5.643 2.821 2.821 0 0 1 0 5.642M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18S27.941 0 18 0"
          />
        </svg>
      );
    case ConnectorCategory.TYPE2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 36 30">
          <path
            fill={colors.inkGrey}
            fillRule="evenodd"
            d="M28.344 17.521a4.362 4.362 0 0 1-4.366-4.358 4.362 4.362 0 0 1 4.366-4.359 4.362 4.362 0 0 1 4.367 4.359 4.362 4.362 0 0 1-4.367 4.358m-5.172 8.719a4.363 4.363 0 0 1-4.366-4.36 4.363 4.363 0 0 1 4.366-4.359 4.363 4.363 0 0 1 4.366 4.36 4.363 4.363 0 0 1-4.366 4.359m-9.538-13.077A4.362 4.362 0 0 1 18 8.804a4.363 4.363 0 0 1 4.366 4.359A4.363 4.363 0 0 1 18 17.52a4.362 4.362 0 0 1-4.366-4.358m-.806 13.077a4.363 4.363 0 0 1-4.367-4.36 4.363 4.363 0 0 1 4.367-4.359 4.363 4.363 0 0 1 4.366 4.36 4.363 4.363 0 0 1-4.366 4.359M3.289 13.163a4.363 4.363 0 0 1 4.367-4.359 4.363 4.363 0 0 1 4.366 4.359 4.363 4.363 0 0 1-4.366 4.358 4.363 4.363 0 0 1-4.367-4.358M12.828 2.03a2.82 2.82 0 0 1 2.82 2.816 2.818 2.818 0 0 1-2.82 2.817 2.819 2.819 0 0 1-2.821-2.817 2.82 2.82 0 0 1 2.82-2.816m10.345-.067a2.82 2.82 0 0 1 2.821 2.816 2.82 2.82 0 0 1-2.821 2.817A2.82 2.82 0 0 1 20.35 4.78a2.819 2.819 0 0 1 2.82-2.816M31.37 0H4.63A17.886 17.886 0 0 0 0 12.03C0 21.955 8.059 30 18 30s18-8.045 18-17.97C36 7.403 34.247 3.185 31.37 0"
          />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 160 160" version="1.1">
          <path
            d="M 64 2.545 C 33.224 9.107, 8.887 33.542, 2.584 64.208 C -7.412 112.845, 30.283 159, 80 159 C 123.409 159, 159 123.409, 159 80 C 159 42.691, 132.396 10.107, 95.792 2.584 C 86.496 0.674, 72.855 0.657, 64 2.545 M 70.500 9.647 C 41.570 14.302, 18.364 35.027, 11.322 62.500 C 8.918 71.878, 8.918 88.122, 11.322 97.500 C 17.578 121.904, 37.384 141.848, 62.085 148.618 C 70.861 151.023, 89.162 151.016, 97.964 148.605 C 122.434 141.902, 141.902 122.419, 148.618 97.913 C 151.026 89.129, 151.016 70.842, 148.599 62 C 141.949 37.670, 122.261 17.847, 98.500 11.558 C 92.315 9.921, 75.802 8.794, 70.500 9.647 M 51 46.500 L 51 55 46 55 L 41 55 41.006 65.750 C 41.009 71.662, 41.457 78.104, 42.002 80.065 C 43.450 85.280, 49.268 91.555, 54.511 93.557 L 59 95.272 59 100.924 C 59 107.937, 61.965 114.163, 67.343 118.442 C 70.780 121.178, 71.904 121.500, 78 121.500 C 84.132 121.500, 85.199 121.189, 88.657 118.394 C 95.209 113.098, 96.349 110.121, 96.959 96.715 L 97.500 84.832 100.766 81.916 C 103.713 79.285, 104.713 79, 111.016 79 L 118 79 118 75 L 118 71 112.250 71.006 C 104.028 71.014, 99.075 72.689, 94.882 76.882 C 90.338 81.427, 89.013 86.031, 89.006 97.300 C 88.998 109.665, 85.981 114.333, 78 114.333 C 70.888 114.333, 67 109.489, 67 100.627 C 67 95.216, 67.041 95.142, 70.655 93.949 C 75.586 92.321, 82.589 85.143, 83.919 80.351 C 84.508 78.233, 84.991 71.662, 84.994 65.750 L 85 55 80 55 L 75 55 75 46.500 L 75 38 71.540 38 L 68.081 38 67.790 46.250 L 67.500 54.500 63 54.500 L 58.500 54.500 58.210 46.250 L 57.919 38 54.460 38 L 51 38 51 46.500 M 48.273 70.148 C 48.867 78.815, 50.119 81.506, 55.385 85.438 C 59.157 88.254, 66.843 88.254, 70.615 85.438 C 75.881 81.506, 77.133 78.815, 77.727 70.148 L 78.286 62 63 62 L 47.714 62 48.273 70.148 "
            stroke="none"
            fill={colors.inkGrey}
            fillRule="evenodd"
          />
        </svg>
      );
  }
};
