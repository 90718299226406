import React from 'react';
import {
  Button as ElementalButton,
  ButtonProps as ElementalButtonProps,
  Loader,
  colors,
} from '@plugsurfing/elemental-ui';

export interface ButtonProps extends ElementalButtonProps {
  loading?: boolean;
}

export const Button = React.memo((props: ButtonProps) => (
  <ElementalButton
    backgroundColor={props.disabled ? colors.cloudGrey : colors.snowWhite}
    status="secondary"
    color={colors.fullBlack}
    width="100%"
    marginTop="1em"
    {...props}
    // @ts-ignore
    loading={undefined}
  >
    {props.loading ? <Loader size={32} color={colors.fullBlack} /> : props.children}
  </ElementalButton>
));
