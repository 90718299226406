import React from 'react';
import { Notification as ElementalUiNotification, colors } from '@plugsurfing/elemental-ui';

export interface NotificationProps {
  children: React.ReactNode;
  onClose?(): void;
}

export const Notification = React.memo(({ children, onClose }: NotificationProps) => (
  <div className="notification-container">
    <div className="content">
      <ElementalUiNotification onClose={onClose} backgroundColor={colors.powerRed}>
        {children}
      </ElementalUiNotification>
    </div>
  </div>
));
