import React from 'react';
import { IconButton, IconInfo, colors, Modal, useToggle } from '@plugsurfing/elemental-ui';
import { Heading, Text } from './';

const modalStyle = {
  maxWidth: '540px',
};

interface InfoPopupProps {
  heading: string;
  text: string;
}

export const InfoPopup = React.memo(({ heading, text }: InfoPopupProps) => {
  const [opened, toggleOpened] = useToggle();

  return (
    <>
      <IconButton status="plain" icon={<IconInfo />} color={colors.fullBlack} onClick={toggleOpened} />
      <Modal opened={opened} onClose={toggleOpened} style={modalStyle}>
        <Heading>{heading}</Heading>
        <Text>{text}</Text>
      </Modal>
    </>
  );
});
