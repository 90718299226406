import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { EnrichedConnector, AdHocSession, AdHocChargePoint, ReservationAmount, AvailablePaymentOptions, PaymentMethodOptions } from 'api';
import * as actions from './actions';
import { checkAvailablePaymentOptions } from 'redux/utils';

export interface State {
  cpoId?: string;
  selectedConnectorId?: string;
  connectors?: EnrichedConnector[];
  session?: AdHocSession;
  userCode?: string;
  reservationAmount?: ReservationAmount;
  availablePaymentOptions: AvailablePaymentOptions;
}

const initialState = {
  cpoId: undefined,
  selectedConnectorId: undefined,
  connectors: undefined,
  session: undefined,
  userCode: undefined,
  reservationAmount: undefined,
  availablePaymentOptions: {
    walletPayment: {
      isLoading: false,
    },
    paymentOptions: [PaymentMethodOptions.CREDIT_CARD],
  },
};

export const reducer = (state: State = initialState, action: Action) => {
  if (isType(action, actions.fetchChargePoints.done)) {
    return {
      ...state,
      cpoId: action.payload.result[0] ? action.payload.result[0].cpoId : undefined,
      connectors: (action.payload.result as AdHocChargePoint[]).reduce(
        (arr: EnrichedConnector[], chargePoint) => [
          ...arr,
          ...chargePoint.connectors.map(connector => ({
            ...connector,
            cpoId: chargePoint.cpoId,
            chargePointLabel: chargePoint.label,
          })),
        ],
        [],
      ),
    };
  }
  if (isType(action, actions.fetchReservationAmount.done)) {
    return {
      ...state,
      reservationAmount: action.payload.result,
    };
  }
  if (isType(action, actions.checkAvailabilityOfWalletPayments.done)) {
    const paymentOptions = checkAvailablePaymentOptions(action.payload.result);
    return {
      ...state,
      availablePaymentOptions: {
        walletPayment: {
          paymentRequest: action.payload.result,
          isLoading: false,
        },
        paymentOptions: paymentOptions,
      },
    };
  }
  if (isType(action, actions.fetchReservationAmount.started)) {
    return {
      ...state,
      availablePaymentOptions: {
        ...state.availablePaymentOptions,
        walletPayment: {
          ...state.availablePaymentOptions.walletPayment,
          isLoading: true,
        },
      },
    };
  }
  if (isType(action, actions.fetchReservationAmount.failed)) {
    return {
      ...state,
      availablePaymentOptions: initialState.availablePaymentOptions,
    };
  }
  if (isType(action, actions.fetchSession.done)) {
    return {
      ...state,
      session: action.payload.result,
    };
  }
  if (isType(action, actions.selectConnector)) {
    return {
      ...state,
      selectedConnectorId: action.payload,
    };
  }
  if (isType(action, actions.setUserCode)) {
    return {
      ...state,
      userCode: action.payload,
    };
  }
  if (isType(action, actions.resetConnector)) {
    return {
      ...state,
      selectedConnectorId: undefined,
      session: undefined,
      userCode: undefined,
    };
  }
  if (isType(action, actions.reset)) {
    return {
      ...initialState,
    };
  }
  return state;
};
