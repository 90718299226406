import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Page, Connector } from 'components';
import { ConnectorStatusEnum } from 'api';
import { selectConnector } from 'redux/actions';
import { selectConnectors } from 'redux/selectors';
import { useInitAppleAndGooglePay } from 'utils/hooks';
import { Navigate, useNavigate } from 'react-router-dom';

const SelectConnectorPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectors = useSelector(selectConnectors());
  useInitAppleAndGooglePay();
  const onSelectConnector = (connectorId: string) => {
    dispatch(selectConnector(connectorId));
    navigate('/payment');
  };

  const occupied = useMemo(
    () => connectors.data?.some((connector) => connector.status === ConnectorStatusEnum.OCCUPIED) || false,
    [connectors.data],
  );

  if (!connectors.data) {
    return <Navigate to="/" />;
  }

  return (
    <Page backNavigation extraContent={occupied && t('occupiedInfo')}>
      {connectors.data.map((connector, index) => (
        <div key={connector.id}>
          {index > 0 && <div className="separator" />}
          <Connector connector={connector} selectConnector={onSelectConnector} />
        </div>
      ))}
    </Page>
  );
};

export default SelectConnectorPage;
