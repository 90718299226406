import { defer, empty } from 'rxjs';
import { catchError, delay, repeatWhen, take } from 'rxjs/operators';
import Config from 'config';
import {
  AdHocChargePoint,
  AdHocStartRequest,
  AdHocStopRequest,
  AdHocStartResponse,
  TermsUrls,
  AdHocSessionRequest,
  AdHocSession,
  Receipt,
  ReservationAmount,
} from './interfaces';

const fetchWrapper = async (url: string, postParams?: object) => {
  const fetchParams = postParams
    ? {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(postParams),
      }
    : undefined;

  const response = await fetch(url, fetchParams);
  const data = await response.json().catch((e) => {
    if (response.ok) {
      return;
    }
    throw e;
  });
  if (response.ok) {
    return data;
  }
  throw data;
};

export const fetchChargePointsByEvseId = (evseId: string): Promise<AdHocChargePoint[]> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/chargers/${evseId}`);
};

export const startAdHocCharging = (request: AdHocStartRequest): Promise<AdHocStartResponse> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/start`, request);
};

export const stopAdHocCharging = (request: AdHocStopRequest): Promise<void> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/stop`, request);
};

export const fetchAdHocTerms = (cpoId: string): Promise<TermsUrls> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/${cpoId}/terms`);
};
export const fetchAdHocReservationAmount = (cpoId: string): Promise<ReservationAmount> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/${cpoId}/reservation-amount`);
};
export const fetchSession = (request: AdHocSessionRequest): Promise<AdHocSession> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/session/search`, request);
};

export const fetchReceiptApi = (request: AdHocSessionRequest): Promise<Receipt> => {
  return fetchWrapper(`${Config.CD_URL}public/v1/ad-hoc/receipt`, request);
};

export const fetchReceipt = (request: AdHocSessionRequest) => {
  return defer(() => fetchReceiptApi(request)).pipe(
    catchError((e: any) => {
      if (e.statusCode === 404) {
        return empty();
      }
      throw e;
    }),
    repeatWhen((s$) => s$.pipe(delay(1000))),
    take(1),
  );
};
