import { PaymentRequest } from '@stripe/stripe-js';

export interface TermsUrls {
  termsUrl: string;
  privacyUrl: string;
}

export interface ReservationAmount {
  reservationAmountMinorUnits: number;
  currency: string;
}

export interface AdHocChargePoint {
  cpoId: string;
  connectors: Connector[];
  label: string;
}

export interface AdHocSessionRequest {
  userCode: string;
  connectorId: string;
}

export interface AdHocSession {
  endTime: number;
  price: Price;
  startTime: number;
  status: SessionStatusEnum;
  writeOff: boolean;
}

export interface Receipt {
  url: string;
}

export interface PriceProfile {
  content: string;
  currency: string;
  description: string;
  id: string;
  name: string;
  organizationId: string;
}

export interface Connector {
  id: string;
  evseId: string;
  label: string;
  priceProfile?: PriceProfile;
  category: ConnectorCategory;
  type: string;
  status: ConnectorStatusEnum;
  // Below not used but available
  quick?: boolean;
  power?: number;
  current?: number;
  voltage?: number;
  number?: number;
}

export interface EnrichedConnector extends Connector {
  cpoId: string;
  chargePointLabel: string;
}

export enum ConnectorCategory {
  CHADEMO = 'CHADEMO',
  DOMESTIC = 'DOMESTIC',
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
  TYPE3 = 'TYPE3',
  CCS = 'CCS',
  OTHER = 'OTHER',
}

export enum ConnectorStatusEnum {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
  UNAVAILABLE = 'UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

interface Price {
  currency: string;
  exclVat: string;
  inclVat: string;
  vat: string;
}

export enum SessionStatusEnum {
  CREATED = 'CREATED',
  WAITINGTOSTART = 'WAITING_TO_START',
  STARTED = 'STARTED',
  WAITINGTOSTOP = 'WAITING_TO_STOP',
  STOPPED = 'STOPPED',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export interface AdHocStartRequest {
  connectorId: string;
  userCode: string;
  reCaptchaResponse: string;
}
export interface AdHocStartResponse {
  clientSecret: string;
}

export interface AdHocStopRequest {
  connectorId: string;
  userCode: string;
  reCaptchaResponse: string;
}

export interface StoredSession {
  userCode: string;
  connectorId: string;
  evseId: string;
  time: number;
}

export enum PaymentMethodOptions {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
}

export interface AvailablePaymentOptions {
  walletPayment: {
    paymentRequest?: PaymentRequest;
    isLoading: boolean;
  };
  paymentOptions: PaymentMethodOptions[];
}

export interface CDCError {
  statusCode: number;
  message: string;
}
