export default {
	en: {
    Fallback: require('./Default.en').default,
  },
	sv: {
    Fallback: require('./Default.sv').default,
  },
	nb: {
    Fallback: require('./Default.nb').default,
  },
	fr: {
    Fallback: require('./Default.fr').default,
  }
};