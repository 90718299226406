import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectChargerForm, { SelectChargerFormProps, evseIdRegex } from './SelectChargerForm';
import { Heading, Page, Text, InfoPopup, LoadingOverlay, Notification } from 'components';
import Config from 'config';
import { setUserCode, selectConnector, fetchChargePoints, fetchSession as fetchSessionAction } from 'redux/actions';
import { selectConnectors } from 'redux/selectors';
import { StoredSession, fetchSession, SessionStatusEnum, CDCError } from 'api';

// Timeouts for navigating back to session (in minutes)
const timeouts = {
  [SessionStatusEnum.COMPLETE]: 72 * 60, // 3 days
  [SessionStatusEnum.STARTED]: 24 * 60, // 24 hours
  [SessionStatusEnum.WAITINGTOSTART]: 1, // 1 minute
};
const maxTimeout = Math.max(...Object.values(timeouts));

const SelectChargerPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectors = useSelector(selectConnectors());
  const [restoringSession, setRestoringSession] = useState<boolean>(false);
  const [restoreChargingSession, setRestoreChargingSession] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const clearError = () => setError(undefined);
  const storedSessionString = useMemo(() => localStorage.getItem(Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION), []);

  const loadChargePoint = useCallback(
    (evseId: string) => {
      dispatch(fetchChargePoints.started(evseId));
    },
    [dispatch],
  );

  const restoreStoredSession = useCallback(
    (storedSession: StoredSession) => {
      setRestoreChargingSession(true);
      loadChargePoint(storedSession.evseId);
      dispatch(setUserCode(storedSession.userCode));
      dispatch(selectConnector(storedSession.connectorId));
    },
    [dispatch, setRestoreChargingSession, loadChargePoint],
  );

  // If there is a valid EVSE ID provided as parameter, use it
  const checkProvidedEvseId = useCallback(() => {
    if (id) {
      if (evseIdRegex.test(id)) {
        setRestoringSession(true);
        loadChargePoint(id);
      } else {
        setError(t('invalidEvseIdParam', { evseId: id }));
        navigate('/', { replace: true, state: {} });
      }
    }
  }, [setRestoringSession, setError, loadChargePoint, navigate, id, t]);

  const checkStoredSession = useCallback(async () => {
    const storedSession: StoredSession = JSON.parse(storedSessionString!);
    const duration = moment.duration(moment().diff(moment(storedSession.time))).asMinutes();

    if (duration <= maxTimeout) {
      setRestoringSession(true);

      try {
        const adHocRequest = {
          connectorId: storedSession.connectorId,
          userCode: storedSession.userCode,
        };
        const adHocSession = await fetchSession(adHocRequest);

        /* 
          Depending on what status and how recent the session started, restore the session.
           Otherwise clear it.
        */
        if (
          (adHocSession.status === SessionStatusEnum.COMPLETE ||
            adHocSession.status === SessionStatusEnum.STARTED ||
            adHocSession.status === SessionStatusEnum.WAITINGTOSTART) &&
          duration < timeouts[adHocSession.status]
        ) {
          dispatch(fetchSessionAction.done({ params: adHocRequest, result: adHocSession }));
          restoreStoredSession(storedSession);
          return;
        }
      } catch (e) {
        const { message, statusCode } = e as CDCError;
        // If session was started within timeout limit but not found, navigate to charging and continue polling for it
        if (statusCode === 404 && duration < Config.SESSION_ABORT_TIMEOUT) {
          restoreStoredSession(storedSession);
          return;
        } else {
          setError(t('restoreSessionError', { error: message || e }));
        }
      }
      setRestoringSession(false);
    }
    // If the stored session is too old to return to, clear it
    localStorage.removeItem(Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION);
    checkProvidedEvseId();
  }, [checkProvidedEvseId, storedSessionString, dispatch, t, restoreStoredSession]);

  useEffect(() => {
    if (storedSessionString) {
      checkStoredSession();
    } else {
      checkProvidedEvseId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connectors.error) {
      switch (connectors.error.statusCode) {
        case 404:
          setError(t('evseIdNotFound'));
          break;
        case 406:
          setError(t('evseIdNotSupported'));
          break;
        default:
          setError(connectors.error.message);
      }
      setRestoringSession(false);
    }
  }, [connectors.error, t]);

  const handleSubmit: SelectChargerFormProps['onSubmit'] = async ({ evseId }) => {
    loadChargePoint(evseId);
  };

  if (connectors.data) {
    // If a session was retreived, go back to it. Otherwise start new flow.
    return restoreChargingSession ? <Navigate to="/charging" /> : <Navigate to="/select-connector" />;
  }

  return (
    <Page cover>
      <div className="content">
        <Heading>{t('welcome')}</Heading>
        <Text>{t('welcomeDescription')}</Text>
        <div className="row-container">
          <Text>{t('findChargerByEvseId')}</Text>
          <InfoPopup heading={t('evseId')} text={t('evseIdDescription')} />
        </div>
        <SelectChargerForm onSubmit={handleSubmit} loading={connectors.loading && !restoringSession} />
      </div>
      {restoringSession && <LoadingOverlay />}
      {error && <Notification onClose={clearError}>{error}</Notification>}
    </Page>
  );
};

export default SelectChargerPage;
