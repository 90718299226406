import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, TextInput } from 'components';

export interface SelectChargerFormProps {
  onSubmit(values: FormData, form: FormikHelpers<FormData>): any;
  loading: boolean;
}

export interface FormData {
  evseId: string;
}
const initialValues: FormData = {
  evseId: '',
};
export const evseIdRegex = /^(?=.{12,}$)([A-Za-z0-9]+\*){3,}[A-Za-z0-9]*$/;
export const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    evseId: Yup.string().matches(evseIdRegex, t('mustBeValidEvseId')),
  });

const SelectChargerForm = ({ onSubmit, loading }: SelectChargerFormProps) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
    >
      {({ handleSubmit, isValid }: FormikProps<FormData>) => (
        <>
          <TextInput type="text" name="evseId" label={t('evseId')} required />
          <Button onClick={handleSubmit as any} disabled={!isValid} loading={loading}>
            {t('search')}
          </Button>
        </>
      )}
    </Formik>
  );
};

export default SelectChargerForm;
