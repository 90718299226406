import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'config/constants';
import { EnrichedConnector, ConnectorStatusEnum } from 'api';
import { Button, InfoItem, HeadingWithIcon, ConnectorIcon } from './';
import { getPriceDescription } from 'utils/helpers';

interface ConnectorProps {
  connector: EnrichedConnector;
  selectConnector(id: string): void;
}

export const Connector = React.memo((props: ConnectorProps) => {
  const { t } = useTranslation();
  const { connector, selectConnector } = props;
  const { id, status } = connector;

  const onClick = () => {
    selectConnector(id);
  };

  return (
    <div className="content" style={{ borderLeft: `4px solid ${colors[status]}` }}>
      <HeadingWithIcon status={status} heading={t(`connectorStatusEnum.${status}`)} />
      {connector.chargePointLabel && <InfoItem label={t('chargePointLabel')} value={connector.chargePointLabel} />}
      <ConnectorInfo connector={connector} />
      <Button onClick={onClick} disabled={status !== ConnectorStatusEnum.AVAILABLE}>
        {t('select')}
      </Button>
    </div>
  );
});

export const ConnectorInfo = (props: { connector: EnrichedConnector }) => {
  const { evseId, label, type, category, priceProfile } = props.connector;
  const { t } = useTranslation();
  const priceDescription = useMemo(() => getPriceDescription(priceProfile), [priceProfile]);
  return (
    <>
      <InfoItem label={t('evseId')} value={evseId} />
      <InfoItem label={t('price')} value={priceDescription} />
      <InfoItem label={t('connector')} value={label} />
      <InfoItem
        label={t('connectorType')}
        value={
          <div className="row-container">
            <div className="icon-container">
              <ConnectorIcon category={category} />
            </div>
            {t(`connectorTypeEnum.${type}`)}
          </div>
        }
      />
    </>
  );
};
