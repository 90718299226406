import moment from 'moment';
import i18n from '../i18n';
import { PriceProfile } from 'api';
import { getCompactPriceDescription, PriceProfileContent, SupportedLanguages } from '@plugsurfing/ps-ocpi-price-format';

export const loadScript = (src: string, onLoad?: any) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = onLoad;
  document.body.appendChild(script);
};

export const formatDuration = (startTime: number, endTime?: number) => {
  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};

export const getErrorMessage = (error: unknown) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return i18n.t('unknownError');
};

export const getPriceDescription = (priceProfile?: PriceProfile) => {
  if (!priceProfile || priceProfile.description === 'FREE_OF_CHARGE') {
    return i18n.t('freeOfCharge');
  }
  const { content, currency, description, id } = priceProfile;
  /*
   * If the price profile is set to use an automatic price description (' ') or it's a markup profile,
   * and the price content and currency is available: calculate an automatic price description.
   */
  if ((description === ' ' || id?.includes('_')) && content && currency) {
    const parsedContent = JSON.parse(content) as PriceProfileContent;
    const language = i18n.language as SupportedLanguages;
    return getCompactPriceDescription({ content: parsedContent, currency, language });
  }
  return description;
};

export const IS_SAFARI_BROWSER = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const formatPrice = (amount: number, currency: string) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency }).format(amount);
};
