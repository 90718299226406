import React, { useMemo, useState, useCallback } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Stripe, StripeElements, StripePaymentRequestButtonElementClickEvent } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { ContentText, Link } from '@plugsurfing/elemental-ui';
import { Button, StripeInput } from 'components';
import { useTranslation, Trans } from 'react-i18next';
import { Checkbox } from 'components';
import { TermsUrls } from 'api';
import { useAppleAndGooglePay } from 'utils/hooks';
import { RadioButtonGroup } from 'components/RadioButtonGroup';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectPaymentOptionsRadio, selectPaymentRequest } from 'redux/selectors';
import { WalletInfo } from 'components/WalletInfo';
export interface PaymentFormProps {
  onSubmit(values: FormData, form: FormikHelpers<FormData>, stripe: Stripe, elements: StripeElements): void;
  loading: boolean;
  terms?: TermsUrls;
}

enum PaymentMethodOptions {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
}

const initialValues: FormData = {
  termsAccepted: false,
  privacyPolicyAccepted: false,
  paymentMethod: PaymentMethodOptions.CREDIT_CARD,
};
export interface FormData {
  termsAccepted: boolean;
  privacyPolicyAccepted: boolean;
  paymentMethod: PaymentMethodOptions;
}

const PaymentForm = ({ onSubmit, loading, terms }: PaymentFormProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const paymentRequest = useSelector(selectPaymentRequest);
  const paymentMethodOptions = useSelector(selectPaymentOptionsRadio);

  const [stripeCardValid, setStripeCardValid] = useState<boolean>(false);
  const [stripeExpiryValid, setStripeExpiryValid] = useState<boolean>(false);
  const [stripeCvcValid, setStripeCvcValid] = useState<boolean>(false); // TODO: Change back to false
  const stripeFieldsValid = stripeCardValid && stripeExpiryValid && stripeCvcValid;
  useAppleAndGooglePay();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        termsAccepted: Yup.boolean().oneOf([true], t('mustBeAccepted')),
        privacyPolicyAccepted: Yup.boolean().oneOf([true], t('mustBeAccepted')),
      }),
    [t],
  );

  const handleSubmitWithStripe = useCallback(
    (formValues: FormData, form: FormikHelpers<FormData>) => {
      if (stripe && elements) {
        onSubmit(formValues, form, stripe, elements);
      }
    },
    [stripe, elements, onSubmit],
  );

  const handlePaymentRequestButtonElementClick = useCallback(
    (
      event: StripePaymentRequestButtonElementClickEvent,
      isValid: FormikProps<FormData>['isValid'],
      setTouched: FormikProps<FormData>['setTouched'],
    ) => {
      if (!isValid) {
        setTouched({ termsAccepted: true, privacyPolicyAccepted: true }, true);
        event.preventDefault();
        return;
      }
    },
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitWithStripe}
      validationSchema={validationSchema}
      isInitialValid={false}
    >
      {({ handleSubmit, isValid, values, setTouched }: FormikProps<FormData>) => (
        <>
          <RadioButtonGroup items={paymentMethodOptions} name={'paymentMethod'} />
          {values.paymentMethod === PaymentMethodOptions.CREDIT_CARD && (
            <>
              <StripeInput StripeElement={CardNumberElement} onValid={setStripeCardValid} />
              <StripeInput StripeElement={CardExpiryElement} onValid={setStripeExpiryValid} />
              <StripeInput StripeElement={CardCvcElement} onValid={setStripeCvcValid} />
            </>
          )}
          {values.paymentMethod !== PaymentMethodOptions.CREDIT_CARD && <WalletInfo />}
          <Checkbox
            name="termsAccepted"
            label={
              <ContentText size="m">
                <Trans i18nKey="termsText">
                  I accept the
                  <Link href={terms?.termsUrl} newTab>
                    Terms and Conditions
                  </Link>
                </Trans>
              </ContentText>
            }
          />
          <Checkbox
            name="privacyPolicyAccepted"
            label={
              <ContentText size="m">
                <Trans i18nKey="privacyPolicyText">
                  I accept the
                  <Link href={terms?.privacyUrl} newTab>
                    Terms and Conditions
                  </Link>
                </Trans>
              </ContentText>
            }
          />
          {values.paymentMethod !== PaymentMethodOptions.CREDIT_CARD && paymentRequest && (
            <StyledPaymentRequestButtonElement
              options={{ paymentRequest: paymentRequest as any, style: { paymentRequestButton: { height: '4rem' } } }}
              onClick={(e) => handlePaymentRequestButtonElementClick(e, isValid, setTouched)}
            />
          )}

          {values.paymentMethod === PaymentMethodOptions.CREDIT_CARD && (
            <Button
              onClick={handleSubmit as any}
              disabled={!isValid || !stripeFieldsValid || loading}
              loading={loading}
            >
              {t('startCharging')}
            </Button>
          )}
        </>
      )}
    </Formik>
  );
};

export default PaymentForm;

const StyledPaymentRequestButtonElement = styled(PaymentRequestButtonElement)`
  margin: 3em 0 3em 0;
`;
