import { AsyncActionCreators } from 'typescript-fsa';
import { defer, of } from 'rxjs';
import { catchError, delay, map, repeatWhen, switchMap, takeUntil, startWith } from 'rxjs/operators';
import { ActionsObservable, ofType } from 'redux-observable';
import { ActionCreator } from 'typescript-fsa/src/index';
import { PaymentMethodOptions } from 'api';
import { IS_SAFARI_BROWSER } from 'utils/helpers';
import { PaymentRequest } from '@stripe/stripe-js';

const POLLING_INTERVAL = 3000;

export const genericPolling =
  <R, P extends ActionCreator<any>, F extends AsyncActionCreators<string, R>, S extends ActionCreator<any>>(
    api: (arg: ReturnType<P>) => Promise<R>,
    startPollingAction: P,
    stopPollingAction: S,
    fetchAction: F,
  ) =>
  (action$: ActionsObservable<ReturnType<P>>) =>
    action$.pipe(
      ofType(startPollingAction.type),
      switchMap((action) =>
        defer(() => api(action)).pipe(
          map((result: R) =>
            fetchAction.done({
              params: action.payload,
              result,
            }),
          ),
          catchError((error) =>
            of(
              fetchAction.failed({
                params: action.payload,
                error,
              }),
            ),
          ),
          startWith({ type: fetchAction.started.type }),
          repeatWhen((done) => done.pipe(delay(POLLING_INTERVAL))),
          takeUntil(action$.ofType(stopPollingAction.type)),
        ),
      ),
    );

export const checkAvailablePaymentOptions = (paymentRequest: PaymentRequest | null) => {
  if (paymentRequest === null) {
    return [PaymentMethodOptions.CREDIT_CARD];
  }
  if (IS_SAFARI_BROWSER) {
    return [PaymentMethodOptions.APPLE_PAY, PaymentMethodOptions.CREDIT_CARD];
  }
  return [PaymentMethodOptions.GOOGLE_PAY, PaymentMethodOptions.CREDIT_CARD];
};
