import React, { useEffect, useState } from 'react';
import { colors } from '@plugsurfing/elemental-ui';
import { formatDuration } from 'utils/helpers';
import { Text } from './';

const INTERVAL = 500;

interface TimerProps {
  startTime: number;
}

export const Timer = React.memo(({ startTime }: TimerProps) => {
  const [duration, setDuration] = useState<string>(formatDuration(startTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(formatDuration(startTime));
    }, INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  return (
    <Text textAlign="center" color={colors.fullBlack} weight="bold" size="xl" marginVertical="5px">
      {duration}
    </Text>
  );
});
