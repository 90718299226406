import { Action as TSAction, AsyncActionCreators } from 'typescript-fsa';
import { Action } from 'redux';

interface CustomError extends Error {
  statusCode?: number;
}

export interface RestState {
  [key: string]: boolean | CustomError | null;
}

const actionsMap = new Map<string, boolean>();

const STARTED = 'STARTED';
const DONE = 'DONE';
const FAILED = 'FAILED';

export function restifyAction<T>(actionCreator: AsyncActionCreators<T, any, any>) {
  actionsMap.set(actionCreator.started.type, true);
  actionsMap.set(actionCreator.done.type, true);
  actionsMap.set(actionCreator.failed.type, true);
}
export const initialState: RestState = {};

const reducer = function (state: RestState, action: Action): RestState {
  if (typeof state === 'undefined') {
    return initialState;
  }
  if (!actionsMap.get(action.type)) {
    return state;
  }

  const [, type, event] = action.type.match(/(.*)_(.*)/);

  switch (event) {
    case STARTED:
      return { ...state, [type]: true };
    case DONE:
      return { ...state, [type]: false };
    case FAILED:
      return { ...state, [type]: (action as TSAction<{ error: CustomError }>).payload.error };
  }
  return state;
}

export default reducer;
