import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import * as actions from './actions';
import { genericPolling } from './utils';
import { fetchSession, fetchChargePointsByEvseId, fetchAdHocReservationAmount } from 'api';

export const fetchChargePoints =
  (api: typeof fetchChargePointsByEvseId) =>
    (action$: ActionsObservable<ReturnType<typeof actions.fetchChargePoints.started>>) =>
      action$.pipe(
        ofType(actions.fetchChargePoints.started.type),
        mergeMap((action) =>
          from(api(action.payload)).pipe(
            map((result) =>
              actions.fetchChargePoints.done({
                params: action.payload,
                result,
              }),
            ),
            catchError((error) =>
              of(
                actions.fetchChargePoints.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
        ),
      );

export const fetchReservationAmount =
  (api: typeof fetchAdHocReservationAmount) =>
    (action$: ActionsObservable<ReturnType<typeof actions.fetchReservationAmount.started>>) =>
      action$.pipe(
        ofType(actions.fetchReservationAmount.started.type),
        mergeMap((action) =>
          from(api(action.payload)).pipe(
            map((result) =>
              actions.fetchReservationAmount.done({
                params: action.payload,
                result,
              }),
            ),
            catchError((error) =>
              of(
                actions.fetchReservationAmount.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
        ),
      );

const pollSession = async (action: ReturnType<typeof actions.startSessionPolling>) => fetchSession(action.payload);

export default combineEpics(
  fetchChargePoints(fetchChargePointsByEvseId),
  fetchReservationAmount(fetchAdHocReservationAmount),
  genericPolling(pollSession, actions.startSessionPolling, actions.stopSessionPolling, actions.fetchSession as any),
);
