import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CardNumberElement } from '@stripe/react-stripe-js';

import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as UUID } from 'uuid';
import PaymentForm, { PaymentFormProps } from './PaymentForm';
import { Heading, Page, Text, Notification } from 'components';
import Config from 'config';
import { setUserCode } from 'redux/actions';
import { startAdHocCharging, fetchAdHocTerms, TermsUrls, StoredSession } from 'api';
import { selectSelectedConnector, selectCpoId, selectReservationAmount } from 'redux/selectors';
import { formatPrice, getErrorMessage } from 'utils/helpers';

const PaymentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const connector = useSelector(selectSelectedConnector);
  const reserveAmount = useSelector(selectReservationAmount);

  const cpoId = useSelector(selectCpoId);
  const [terms, setTerms] = useState<TermsUrls | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const clearError = () => setError(undefined);

  const handleSubmit: PaymentFormProps['onSubmit'] = useCallback(
    async (values, form, stripe, elements) => {
      setLoading(true);
      try {
        const cardElement = elements.getElement(CardNumberElement);
        if (!cardElement) {
          throw new Error(t('stripeElementsError'));
        }

        // Step 1. Create payment method in Stripe from card details
        const paymentMethodReponse = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        if (paymentMethodReponse.error) {
          throw new Error(paymentMethodReponse.error.message);
        }

        const userCode = UUID();

        // Step 2. Get reCAPTCHA token from Google
        const reCaptchaResponse = await grecaptcha.execute(Config.GOOGLE_RECAPTCHA_SITE_KEY, {
          action: 'adhocpayment',
        });

        // Step 3. Make a start request to CD API by sending reCAPTCHA token + generated code + details
        const { clientSecret } = await startAdHocCharging({
          connectorId: connector!.id,
          userCode,
          reCaptchaResponse,
        });

        // Step 4. Confirm the payment intent. Stripe will automatically authenticate 3D secure.
        const confirmationResponse = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethodReponse.paymentMethod?.id,
        });
        if (confirmationResponse.error) {
          throw new Error(confirmationResponse.error.message);
        }

        dispatch(setUserCode(userCode));
        localStorage.setItem(
          Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION,
          JSON.stringify({
            userCode,
            connectorId: connector!.id,
            evseId: connector!.evseId,
            time: new Date().getTime(),
          } as StoredSession),
        );
        navigate('/charging', { replace: true });
      } catch (e) {
        setError(getErrorMessage(e));
        setLoading(false);
      }
    },
    [dispatch, t, connector, navigate, setError],
  );

  const fetchTerms = useCallback(async () => {
    if (cpoId) {
      try {
        const termsResponse = await fetchAdHocTerms(cpoId);
        setTerms(termsResponse);
      } catch (e) {
        setError(t('termsError', { error: getErrorMessage(e) }));
      }
    }
  }, [cpoId, t]);

  const amountDescription = useMemo(() => {
    if (reserveAmount) return formatPrice(reserveAmount.reservationAmountMinorUnits / 100, reserveAmount.currency);
  }, [reserveAmount]);

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  if (!connector) {
    return <Navigate to="/" />;
  }

  return (
    <Page
      backNavigation={!loading}
      extraContent={
        <div className="notice-text captcha-text">
          <Trans i18nKey="googleRecaptchaMessage">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            and
            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            apply.
          </Trans>
        </div>
      }
    >
      <div className="content">
        <Heading>{t('paymentHeading')}</Heading>
        <Text>{t('choosePaymentDescription')}</Text>
        <PaymentForm onSubmit={handleSubmit} loading={loading} terms={terms} />
        <p className="notice-text">{t('reserveAmountDescription', { amount: amountDescription })}</p>
      </div>
      {error && <Notification onClose={clearError}>{error}</Notification>}
    </Page>
  );
};

export default PaymentPage;
