import { AdHocChargePoint, AdHocSession, AdHocSessionRequest, ReservationAmount } from 'api';
import actionCreatorFactory from 'typescript-fsa';
import { PaymentRequest } from '@stripe/stripe-js';

const actionCreator = actionCreatorFactory();

export const fetchChargePoints = actionCreator.async<string, AdHocChargePoint[], Error>('FETCH_CHARGEPOINT');
export const fetchReservationAmount = actionCreator.async<string, ReservationAmount, Error>('FETCH_RESERVATION_AMOUNT');
export const checkAvailabilityOfWalletPayments = actionCreator.async<undefined, PaymentRequest | null, Error>(
  'CHECK_WALLET_PAYMENTS_AVAILABILITY',
);

export const startSessionPolling = actionCreator<AdHocSessionRequest>('START_SESSION_POLLING');
export const stopSessionPolling = actionCreator('STOP_SESSION_POLLING');
export const fetchSession = actionCreator.async<AdHocSessionRequest, AdHocSession, Error>('FETCH_SESSION');

export const selectConnector = actionCreator<string>('SELECT_CONNECTOR');
export const setUserCode = actionCreator<string>('SET_USER_CODE');

export const resetConnector = actionCreator('RESET_CONNECTOR');
export const reset = actionCreator('RESET');
