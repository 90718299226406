import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@plugsurfing/elemental-ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinkButton } from 'components';
import * as actions from 'redux/actions';
import { Heading, ChargingIcon } from './';

interface PageProps {
  children?: ReactNode;
  extraContent?: ReactNode;
  cover?: boolean;
  backNavigation?: boolean;
}

export const Page = React.memo(({ children, extraContent, cover, backNavigation }: PageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateBackToStart = useCallback(() => {
    localStorage.clear();
    dispatch(actions.reset());
    navigate('/', { replace: true });
  }, [dispatch, navigate]);

  return (
    <>
      <div>
        <div className="header">
          <ChargingIcon />
          <Heading level={3} color={colors.snowWhite} marginLeft="0.5em" marginTop={0}>
            {t('nonRegisteredUser')}
          </Heading>
        </div>
      </div>
      <div className="section-container">
        <div className={`section${cover ? ' cover' : ''}`}>
          <div className="section-content">{children}</div>
          <div className="section-extra">{extraContent}</div>
        </div>
        {backNavigation && <LinkButton onClick={navigateBackToStart}>{t('backToStart')}</LinkButton>}
      </div>
    </>
  );
});
