export default {
  "APPLE_PAY": "Apple Pay",
  "CREDIT_CARD": "Credit Card",
  "GOOGLE_PAY": "Google Pay",
  "backToStart": "Back to start",
  "cconnectorTypeEnum": {
    "TESLA_S": "TESLA_S"
  },
  "chargePointLabel": "Charging station",
  "chargerAvailable": "The charger is free!",
  "chargingCompleted": "Thank you for charging!",
  "chargingSession": "We will reserve this amount to start charging",
  "choosePaymentDescription": "To start charging choose your payment method",
  "connector": "Connector",
  "connectorStatusEnum": {
    "AVAILABLE": "Available",
    "NO_DATA": "Unknown status",
    "OCCUPIED": "Occupied",
    "OUT_OF_ORDER": "Out of order",
    "RESERVED": "Reserved",
    "UNAVAILABLE": "Unavailable",
    "UNKNOWN": "Unknown"
  },
  "connectorType": "Type",
  "connectorTypeEnum": {
    "CCS": "CCS",
    "CHADEMO": "CHAdeMO",
    "CHADEMO_AND_CCS": "CHAdeMO and CSS",
    "DOMESTIC_I": "Domestic I",
    "GBT": "GBT",
    "IEC_62196_T1": "Type 1",
    "IEC_62196_T1_COMBO": "CCS",
    "IEC_62196_T2": "Type 2",
    "IEC_62196_T2_COMBO": "CCS",
    "IEC_62196_T3A": "IEC_62196_T3A",
    "IEC_62196_T3C": "IEC_62196_T3C",
    "SCHUKO": "Schuko",
    "TESLA_R": "TESLA_R",
    "TYPE1": "Type 1",
    "TYPE1_CABLE": "Type 1 Cable",
    "TYPE2": "Type 2",
    "TYPE2_AND_SCHUKO": "Type 2 and Schuko",
    "TYPE2_CABLE": "Prise Type 2 Cable",
    "TYPE3A": "TYPE 3A",
    "TYPE3A_AND_TYPE2": "Type 3A and Type 2",
    "TYPE3C_AND_SCHUKO": "Type 3C and Schuko",
    "TYPE3C_AND_TYPE2": "Type 3C and Type 2",
    "UNKNOWN": "Unknown"
  },
  "domainCancellationWarning": "This domain will be discontinued on 01-June-2023. To continue using this portal please migrate to:",
  "duration": "Duration",
  "evseId": "EVSE ID",
  "evseIdDescription": "The EVSE ID is a code that is used to identify a specific charge point. The code can usually be found on the charger and starts with a country code (e.g. GB for Great Britain).",
  "evseIdNotFound": "No chargers found",
  "evseIdNotSupported": "Charger not supported",
  "failedToStartCharging": "Charging start FAILED",
  "findChargerByEvseId": "Find your charger by EVSE ID",
  "freeOfCharge": "Free of charge",
  "generatingReceipt": "Generating receipt...",
  "googleRecaptchaMessage": "This site is protected by reCAPTCHA and the Google <1>Privacy Policy</1> and <3>Terms of Service</3> apply.",
  "googleRecaptchaTerms": "This site is protected by reCAPTCHA and the Google <1>Privacy Policy</1> and <2>Terms of Service</2> apply.",
  "initiatingChargingSessionMessage": "Please make sure you have plugged the cable in the car and the charger",
  "invalidEvseIdParam": "The provided EVSE ID is not valid: {{evseId}}",
  "mustBeAccepted": "Must be accepted",
  "mustBeValidEvseId": "Must be valid EVSE ID, e.g. SE*CND*ESIMSWE1*1",
  "mustBeValidEvseIdShort": "Invalid EVSE ID",
  "noChargingSessionFound": "Failed to find charging session",
  "nonRegisteredUser": "Non-registered user",
  "occupiedInfo": "This charger is currently occupied. Should you be the driver of the car currently charging, please try ending the charging sesion from the vehicle, which should stop the charging session and release the cable.",
  "paymentDescription": "To start charging fill in your payment card details.",
  "paymentHeading": "Payment information",
  "price": "Price",
  "privacyPolicyText": "I accept the <1>Privacy Policy</1>",
  "reserveAmountDescription": "{{amount}} will be reserved on your payment card to make sure there are sufficient funds to complete the payment.",
  "restoreSessionError": "Failed to restore previous session. {{error}}",
  "search": "Search",
  "select": "Select",
  "sendingStartRequest": "Sending start request...",
  "sessionFailed": "Failed to fetch charging session. {{error}}",
  "sessionStatusEnum": {
    "COMPLETE": "Session completed",
    "CREATED": "Session created",
    "FAILED": "Session failed",
    "STARTED": "Charging",
    "STOPPED": "Session stopped",
    "WAITING_TO_START": "Waiting to start",
    "WAITING_TO_STOP": "Waiting to stop"
  },
  "startCharging": "Start charging",
  "stopCharging": "Stop charging",
  "stopError": "Failed to send stop request. {{error}}",
  "stripeElementsError": "Failed to collect card details",
  "termsError": "Failed to load terms. {{error}}",
  "termsText": "I accept the <1>Terms & Conditions</1>",
  "totalCost": "Total cost",
  "tryAgain": "Try again",
  "unknownError": "Something went wrong",
  "viewReceipt": "View your receipt",
  "walletInfoDescription": "Click “{{wallet}}” and confirm your purchase and start charging.",
  "welcome": "Welcome!",
  "welcomeDescription": "Here you can start your EV charging without registering as a customer."
};
