import { useStripe } from '@stripe/react-stripe-js';
import { startAdHocCharging, StoredSession } from 'api';
import Config from 'config';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkAvailabilityOfWalletPayments, fetchReservationAmount, setUserCode } from 'redux/actions';
import { selectCpoId, selectPaymentRequest, selectReservationAmount, selectSelectedConnector } from 'redux/selectors';
import { Subject } from 'rxjs';
import { v4 as UUID } from 'uuid';

export function useUnmount() {
  const unmount$ = useRef<Subject<any>>(undefined!);
  if (!unmount$.current) {
    unmount$.current = new Subject();
  }

  useEffect(() => {
    return () => {
      unmount$.current.next();
      unmount$.current.complete();
    };
  }, []);

  return unmount$.current;
}

export function useInitAppleAndGooglePay() {
  const stripe = useStripe();

  const reservationAmount = useSelector(selectReservationAmount);
  const cpoId = useSelector(selectCpoId);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cpoId) dispatch(fetchReservationAmount.started(cpoId));
  }, [cpoId, dispatch]);

  useEffect(() => {
    if (!reservationAmount) return;
    if (!stripe) return;

    dispatch(checkAvailabilityOfWalletPayments.started(undefined));

    const pr = stripe.paymentRequest({
      country: 'DE',
      currency: reservationAmount.currency.toLowerCase(),
      total: {
        label: t('chargingSession'),
        amount: reservationAmount.reservationAmountMinorUnits,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result !== null) {
        dispatch(checkAvailabilityOfWalletPayments.done({ params: undefined, result: pr }));
      }
      dispatch(checkAvailabilityOfWalletPayments.failed({ error: new Error() }));
    });
  }, [stripe, reservationAmount, dispatch, t]);
}

export function useAppleAndGooglePay() {
  const stripe = useStripe();

  const pr = useSelector(selectPaymentRequest);
  const connector = useSelector(selectSelectedConnector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (pr && stripe) {
      pr.on('paymentmethod', async (e) => {
        const reCaptchaResponse = await grecaptcha.execute(Config.GOOGLE_RECAPTCHA_SITE_KEY, {
          action: 'adhocpayment',
        });

        const userCode = UUID();
        const { clientSecret } = await startAdHocCharging({
          connectorId: connector!.id,
          userCode,
          reCaptchaResponse,
        });

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false },
        );

        if (stripeError) {
          e.complete('fail');
          throw new Error(stripeError.message);
        }
        e.complete('success');

        if (paymentIntent!.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            e.complete('fail');
            throw new Error(error.message);
          } else {
            e.complete('success');
          }
        }

        dispatch(setUserCode(userCode));
        localStorage.setItem(
          Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION,
          JSON.stringify({
            userCode,
            connectorId: connector!.id,
            evseId: connector!.evseId,
            time: new Date().getTime(),
          } as StoredSession),
        );
        navigate('/charging', { replace: true });
      });
    }
  }, [stripe, connector, pr, dispatch, navigate]);
}
