import { colors as elementalColors } from '@plugsurfing/elemental-ui';

const statusColors = {
  GREEN: '#5ac37d',
  BLUE: elementalColors.cushyBlue,
  RED: '#d25564',
};

export const colors = {
  AVAILABLE: statusColors.GREEN,
  STARTING: statusColors.GREEN,
  CHARGING: statusColors.BLUE,
  OCCUPIED: statusColors.BLUE,
  OUT_OF_ORDER: statusColors.RED,
  NO_DATA: elementalColors.fogGrey,
  CREATED: statusColors.GREEN,
  WAITING_TO_START: statusColors.BLUE,
  STARTED: statusColors.BLUE,
  WAITING_TO_STOP: statusColors.BLUE,
  STOPPED: statusColors.RED,
  COMPLETE: statusColors.GREEN,
  FAILED: statusColors.RED,
};

export interface SharedConfiguration {
  LOCAL_STORAGE_KEYS: typeof localStorageKeys;
  POLL_CHARGEPOINT_INTERVALL: number;
  POLL_SESSION_INTERVALL: number;
  SESSION_ABORT_TIMEOUT: number;
}

const localStorageKeys = {
  ONGOING_SESSION: 'ongoingsession',
};

export const sharedConfiguration: SharedConfiguration = {
  LOCAL_STORAGE_KEYS: localStorageKeys,
  POLL_CHARGEPOINT_INTERVALL: 5000, // 5 seconds
  POLL_SESSION_INTERVALL: 3000, // 3 seconds
  SESSION_ABORT_TIMEOUT: 180000, // 3 minutes
};
