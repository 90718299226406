import React from 'react';
import { ContentText, colors } from '@plugsurfing/elemental-ui';

interface InfoItemProps {
  label: string;
  value?: React.ReactNode;
}

export const InfoItem = React.memo(({ label, value }: InfoItemProps) => (
  <>
    <ContentText size="xs" color={colors.fogGrey} marginTop="1em" marginBottom="0.2em">
      {label}
    </ContentText>
    {typeof value === 'string' ? <ContentText>{value}</ContentText> : value}
  </>
));
