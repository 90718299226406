import React from 'react';
import { colors, Row, Col, ContentText, IconInfo } from '@plugsurfing/elemental-ui';
import { useTranslation } from 'react-i18next';

import { IS_SAFARI_BROWSER } from 'utils/helpers';
import { PaymentMethodOptions } from 'api';

export const WalletInfo = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Row display="flex" paddingTop="1em" paddingBottom="1em" style={{ borderTop: `1px solid ${colors.fogGrey}` }}>
      <Col xxs={1}>
        <IconInfo />
      </Col>
      <Col xxs={10}>
        <ContentText size="s">
          {t('walletInfoDescription', {
            wallet: IS_SAFARI_BROWSER ? t(PaymentMethodOptions.APPLE_PAY) : t(PaymentMethodOptions.GOOGLE_PAY),
          })}
        </ContentText>
      </Col>
    </Row>
  );
});
