import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import SelectChargerPage from './views/SelectCharger/SelectChargerPage';
import SelectConnectorPage from './views/SelectConnector/SelectConnectorPage';
import PaymentPage from './views/Payment/PaymentPage';
import ChargingPage from './views/Charging/ChargingPage';
import store from './redux';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Config from 'config';
import { getI18n } from 'react-i18next';

const stripePromise = loadStripe(Config.STRIPE_API_KEY);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise} options={{ locale: getI18n().language as any }}>
        <Router>
          <Routes>
            <Route path="/.well-known/apple-developer-merchantid-domain-association" />
            <Route path="/charging" element={<ChargingPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/select-connector" element={<SelectConnectorPage />} />
            <Route path="/:id" element={<SelectChargerPage />} />
            <Route path="/" element={<SelectChargerPage />} />
          </Routes>
        </Router>
      </Elements>
    </Provider>
  );
};

export default App;
