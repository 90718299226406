import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { Page, ConnectorInfo, Button, Text, HeadingWithIcon, Heading, Timer, Notification, InfoItem } from 'components';
import { startSessionPolling, stopSessionPolling, fetchChargePoints, resetConnector } from 'redux/actions';
import { colors } from 'config/constants';
import Config from 'config';
import { SessionStatusEnum, stopAdHocCharging, fetchReceipt, AdHocSession, StoredSession } from 'api';
import { selectSession, selectSelectedConnector, selectUserCode } from 'redux/selectors';
import { formatDuration, getErrorMessage, IS_SAFARI_BROWSER } from 'utils/helpers';
import { useUnmount } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { IconCheck, Link, Loader, colors as elementalColors } from '@plugsurfing/elemental-ui';
import { takeUntil } from 'rxjs/operators';

const hasPrice = (sessionData: AdHocSession) =>
  Number(sessionData.price?.inclVat) > 0 && sessionData.writeOff === false;

const ChargingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userCode = useSelector(selectUserCode);
  const session = useSelector(selectSession());
  const connector = useSelector(selectSelectedConnector);
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const [stopLoading, setStopLoading] = useState<boolean>(false);
  const storedSession: StoredSession = useMemo(
    () =>
      localStorage.getItem(Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION) &&
      JSON.parse(localStorage.getItem(Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION)!),
    [],
  );

  const [timedOut, setTimedOut] = useState<boolean>(false);
  const [receiptLoading, setReceiptLoading] = useState<boolean>(false);
  const unmount$ = useUnmount();

  const clearError = () => setError(undefined);

  const timerRef = useRef<NodeJS.Timeout>();

  // If session is considered timed out, set it to FAILED manually
  const sessionStatus = timedOut ? SessionStatusEnum.FAILED : session.data?.status;

  const isCharging = sessionStatus === SessionStatusEnum.STARTED;

  const goToConnectorSelection = useCallback(() => {
    dispatch(resetConnector());
    navigate('/select-connector', { replace: true });
  }, [dispatch, navigate]);

  // Used when no session is found after x seconds
  const abortWithMessage = useCallback(
    (message: string) => {
      setError(message);
      setTimedOut(true);
    },
    [setError, setTimedOut],
  );

  const loadChargePoint = useCallback(
    (evseId: string) => {
      dispatch(fetchChargePoints.started(evseId));
    },
    [dispatch],
  );

  const loadReceipt = useCallback(() => {
    if (userCode && connector) {
      setReceiptLoading(true);
      fetchReceipt({ userCode, connectorId: connector.id })
        .pipe(takeUntil(unmount$))
        .subscribe(
          (receipt) => {
            setReceiptLoading(false);
            IS_SAFARI_BROWSER ? window.location.assign(receipt.url) : window.open(receipt.url, '_blank');
          },
          (e: Error) => {
            setReceiptLoading(false);
            setError(t('receiptError', { error: e?.message || e }));
          },
        );
    }
  }, [userCode, connector, unmount$, setError, setReceiptLoading, t]);

  const stopCharging = async () => {
    if (userCode && connector) {
      setStopLoading(true);
      try {
        const reCaptchaResponse = await grecaptcha.execute(Config.GOOGLE_RECAPTCHA_SITE_KEY, {
          action: 'adhocstoprequest',
        });
        await stopAdHocCharging({
          userCode,
          connectorId: connector.id,
          reCaptchaResponse,
        });
      } catch (e) {
        setError(t('stopError', { error: getErrorMessage(e) }));
        setStopLoading(false);
      }
    }
  };

  useEffect(() => {
    if (sessionStatus && timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (storedSession && sessionStatus === SessionStatusEnum.STARTED) {
      loadChargePoint(storedSession.evseId);
    }
    if (sessionStatus === SessionStatusEnum.WAITINGTOSTART) {
      timerRef.current = setTimeout(() => abortWithMessage(t('failedToStartCharging')), Config.SESSION_ABORT_TIMEOUT);
    }
    // If it fails to start, stop polling status and clear the session
    if (
      sessionStatus === SessionStatusEnum.FAILED ||
      (sessionStatus === SessionStatusEnum.COMPLETE &&
        (session.data?.writeOff !== undefined || Number(session.data?.price?.inclVat) === 0))
    ) {
      dispatch(stopSessionPolling());
      localStorage.removeItem(Config.LOCAL_STORAGE_KEYS.ONGOING_SESSION);
    }
  }, [session.data, sessionStatus, storedSession, abortWithMessage, loadChargePoint, dispatch, t]);

  useEffect(() => {
    if (session.error && session.error.statusCode !== 404) {
      setError(t('sessionFailed', { error: session.error.message }));
    }
  }, [session.error, t]);

  useEffect(() => {
    if (connector && userCode) {
      if (!sessionStatus) {
        timerRef.current = setTimeout(
          () => abortWithMessage(t('noChargingSessionFound')),
          Config.SESSION_ABORT_TIMEOUT,
        );
      }
      dispatch(
        startSessionPolling({
          connectorId: connector.id,
          userCode: userCode,
        }),
      );
      return () => {
        dispatch(stopSessionPolling());
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (connector) {
    return (
      <Page backNavigation={sessionStatus === SessionStatusEnum.COMPLETE || sessionStatus === SessionStatusEnum.FAILED}>
        <div className="content">
          {sessionStatus ? (
            <HeadingWithIcon
              status={sessionStatus}
              heading={t(`sessionStatusEnum.${sessionStatus}`)}
              loading={session.loading}
            />
          ) : (
            <Heading>{t('sendingStartRequest')}</Heading>
          )}
          {connector.chargePointLabel && <InfoItem label={t('chargePointLabel')} value={connector.chargePointLabel} />}
          <ConnectorInfo connector={connector} />
          {sessionStatus === SessionStatusEnum.WAITINGTOSTART && (
            <Text textColor={colors.CHARGING} textAlign="center">
              {t('initiatingChargingSessionMessage')}
            </Text>
          )}
          {isCharging && session.data?.startTime && <Timer startTime={session.data.startTime} />}
          {isCharging && (
            <Button
              onClick={stopCharging}
              marginBottom="0"
              color={colors.STOPPED}
              disabled={stopLoading}
              loading={stopLoading}
            >
              {t('stopCharging')}
            </Button>
          )}
          {sessionStatus === SessionStatusEnum.FAILED && (
            <Button
              onClick={goToConnectorSelection}
              marginBottom="0"
              // color={colors.STOPPED}
            >
              {t('tryAgain')}
            </Button>
          )}
          {sessionStatus === SessionStatusEnum.COMPLETE && (
            <div className="center-container">
              <div className="icon-circle">
                <IconCheck size="30" />
              </div>
              <Text>{t('chargingCompleted')}</Text>
              <Text marginVertical="0">{`${t('totalCost')}: `}</Text>
              <Text
                marginVertical="0"
                weight="bold"
              >{`${session.data?.price?.inclVat} ${session.data?.price?.currency}`}</Text>
              <Text marginVertical="0">{`${t('duration')}: `}</Text>
              <Text marginVertical="0" weight="bold">
                {formatDuration(session.data!.startTime, session.data?.endTime)}
              </Text>
              {hasPrice(session.data!) && !receiptLoading && <Link onClick={loadReceipt}>{t('viewReceipt')}</Link>}
              {receiptLoading && (
                <div className="row-container">
                  <Text color={elementalColors.cushyBlue}>{t('generatingReceipt')}</Text>
                  <Loader size={16} color={elementalColors.cushyBlue} marginLeft="10px" />
                </div>
              )}
            </div>
          )}
        </div>
        {error && <Notification onClose={clearError}>{error}</Notification>}
      </Page>
    );
  }
  return <Navigate to="/" />;
};

export default ChargingPage;
