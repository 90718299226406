import React from 'react';
import { useField } from 'formik';
import {
  Checkbox as ElementalCheckbox,
  CheckboxProps,
  ContentText,
  ErrorMessage,
  Box,
  Gap,
} from '@plugsurfing/elemental-ui';

export const Checkbox = React.memo((props: CheckboxProps) => {
  const [field, meta] = useField(props as any);
  const isLabelString = typeof props.label === 'string';

  return (
    <Box marginVertical={10} marginTop="1.5em">
      <Gap size="8px">
        <ElementalCheckbox {...field} {...props} label={isLabelString && props.label} width="100%" />
        {!isLabelString && props.label}
      </Gap>
      <ErrorMessage active={meta.touched && !!meta.error}>
        <ContentText>{meta.error}</ContentText>
      </ErrorMessage>
    </Box>
  );
});
