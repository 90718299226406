import React from 'react';
import { Loader, colors } from '@plugsurfing/elemental-ui';
import { colors as statusColors } from 'config/constants';
import { ConnectorStatusEnum, SessionStatusEnum } from 'api';
import { Heading, StatusIcon } from './';

const headingColor = {
  ...statusColors,
};

export interface HeadingWithIconProps {
  heading: string;
  status: ConnectorStatusEnum | SessionStatusEnum;
  loading?: boolean;
}

export const HeadingWithIcon = ({ status, heading, loading }: HeadingWithIconProps) => (
  <div className="row-container status-header">
    <div className="icon-container">
      <StatusIcon status={status} />
    </div>
    <Heading color={headingColor[status]} flex={1} marginTop={0}>
      {heading}
    </Heading>
    {loading && <Loader size={16} color={colors.cushyBlue} />}
  </div>
);
