import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import '@plugsurfing/elemental-ui/FortumSans.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { loadScript } from 'utils/helpers';
import Config from 'config';

const loadGoogleReCAPTCHA = () => {
  const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${Config.GOOGLE_RECAPTCHA_SITE_KEY}`;
  loadScript(scriptSrc);
};
const rootNode = document.getElementById('root');
const root = ReactDOM.createRoot(rootNode as HTMLElement);

const render = () => {
  root.render(<App />);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

(() => {
  render();
  loadGoogleReCAPTCHA();
})();
