import { SharedConfiguration, sharedConfiguration } from 'config/constants';
import { Configuration, configurationProd, configurationStage, configurationTest } from 'config/targets';

function getEnvironmentConfiguration(subdomain: string) {
  const env = extractEnvFromSubdomain(subdomain);
  if (env === 'test') {
    return configurationTest;
  }
  if (env === 'stage') {
    return configurationStage;
  }
  return configurationProd;
}

function extractEnvFromSubdomain(subdomain: string): 'test' | 'stage' | 'prod' {
  if (subdomain.endsWith('-test')) {
    return 'test';
  }
  if (subdomain.endsWith('-stage')) {
    return 'stage';
  }
  return 'prod';
}
function getConfiguration(location: Location): Configuration & SharedConfiguration {
  // local development
  if (
    location.host.startsWith('localhost') ||
    location.host.startsWith('192.168') ||
    location.host.startsWith('0.0.0.0')
  ) {
    return { ...configurationTest, ...sharedConfiguration };
  }
  const subdomain = location.host.split('.')[0];

  return { ...getEnvironmentConfiguration(subdomain), ...sharedConfiguration };
}

export default getConfiguration;
