export default {
  "backToStart": "Tillbaka till start",
  "cconnectorTypeEnum": {
    "TESLA_S": "TESLA_S"
  },
  "chargePointLabel": "Laddstation",
  "chargerAvailable": "Laddaren är gratis!",
  "chargingCompleted": "Tack för att du laddat!",
  "connector": "Uttag",
  "connectorStatusEnum": {
    "AVAILABLE": "Tillgänglig",
    "NO_DATA": "Ingen data",
    "OCCUPIED": "Upptagen",
    "OUT_OF_ORDER": "Ur funktion",
    "RESERVED": "Reserverad",
    "UNAVAILABLE": "Inte tillgänglig",
    "UNKNOWN": "Okänd"
  },
  "connectorType": "Typ",
  "connectorTypeEnum": {
    "CCS": "CCS",
    "CHADEMO": "CHAdeMO",
    "CHADEMO_AND_CCS": "CHAdeMO och CCS",
    "GBT": "GBT",
    "IEC_62196_T1": "Typ 1",
    "IEC_62196_T1_COMBO": "CCS",
    "IEC_62196_T2": "Typ 2",
    "IEC_62196_T2_COMBO": "CCS",
    "IEC_62196_T3A": "IEC_62196_T3A",
    "IEC_62196_T3C": "IEC_62196_T3C",
    "SCHUKO": "Schuko",
    "TESLA_R": "TESLA_R",
    "TYPE1": "Typ 1",
    "TYPE1_CABLE": "Typ 1 kabel",
    "TYPE2": "Typ 2",
    "TYPE2_AND_SCHUKO": "Typ 2 och Schuko",
    "TYPE2_CABLE": "Typ 2 kabel",
    "TYPE3A": "Typ 3A",
    "TYPE3A_AND_TYPE2": "Typ 3A och Typ 2",
    "TYPE3C_AND_SCHUKO": "Typ 3C och Schuko",
    "TYPE3C_AND_TYPE2": "Typ 3C och Typ 2",
    "UNKNOWN": "Okänd"
  },
  "duration": "Varaktighet",
  "evseId": "EVSE-ID",
  "evseIdDescription": "EVSE ID är en kod som används för att identifiera ett ladduttag. Koden kan ofta hittas på laddaren och börjar med en landskod (t.ex. SE för Sverige).",
  "evseIdNotFound": "Inga laddare hittades",
  "evseIdNotSupported": "Laddaren stöds inte",
  "failedToStartCharging": "Lyckades inte starta laddning",
  "findChargerByEvseId": "Hitta din laddare via EVSE-ID",
  "generatingReceipt": "Genererar kvitto...",
  "googleRecaptchaMessage": "Denna sida är skyddad av reCAPTCHA och Googles <1>Integritetspolicy</1> och <3>Användarvillkor</3> gäller.",
  "googleRecaptchaTerms": "Denna sida är skyddad av reCAPTCHA och Googles <1>Integritetspolicy</2> och <2>Användarvillkor</2> gäller.",
  "initiatingChargingSessionMessage": "Vänligen se till att du har kopplat in kabeln i bilen och laddaren",
  "invalidEvseIdParam": "Det specificerade EVSE-ID:t är inte giltigt:",
  "mustBeAccepted": "Måste accepteras",
  "mustBeValidEvseId": "Måste vara giltigt EVSE-ID, t.ex. SE*CND*ESIMSWE1*1",
  "mustBeValidEvseIdShort": "Ogiltigt EVSE-ID",
  "noChargingSessionFound": "Hittade inte laddningen",
  "nonRegisteredUser": "Oregistrerad användare",
  "paymentDescription": "För att starta, fyll i kortuppgifter",
  "paymentHeading": "Betalningsinformation",
  "price": "Pris",
  "privacyPolicyText": "Jag accepterar <1>integritetspolicyn</1>",
  "restoreSessionError": "Den tidigare laddningen hittades inte",
  "search": "Sök",
  "select": "Välj",
  "sendingStartRequest": "Skickar startanrop till laddaren...",
  "sessionFailed": "Misslyckades att hitta laddningen",
  "sessionStatusEnum": {
    "COMPLETE": "Laddningen är färdig",
    "CREATED": "Laddningssession skapad",
    "FAILED": "Laddning misslyckades",
    "STARTED": "Laddar",
    "STOPPED": "Laddning stoppad",
    "WAITING_TO_START": "Laddning startas...",
    "WAITING_TO_STOP": "Avslutar laddning..."
  },
  "startCharging": "Starta laddning",
  "stopCharging": "Stoppa laddning",
  "stopError": "Misslyckades att stoppa laddningen",
  "stripeElementsError": "Misslyckades att registrera betalkort",
  "termsError": "Misslyckades att hämta villkoren. {{error}}",
  "termsText": "Jag accepterar <1>villkoren</1>",
  "totalCost": "Total kostnad",
  "tryAgain": "Försök igen",
  "unknownError": "Någonting gick fel",
  "viewReceipt": "Se ditt kvitto",
  "welcome": "Välkommen!",
  "welcomeDescription": "Här kan du starta din laddning utan att registrera dig"
};
