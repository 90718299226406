import React from 'react';
import { ConnectorStatusEnum, SessionStatusEnum } from 'api';
import { colors } from 'config/constants';

export interface StatusIconProps {
  status: ConnectorStatusEnum | SessionStatusEnum;
}

export const StatusIcon = ({ status }: StatusIconProps) => {
  switch (status) {
    case ConnectorStatusEnum.AVAILABLE:
    case SessionStatusEnum.COMPLETE:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill={colors[status]} fillRule="evenodd">
            <path d="M8 1.28A6.728 6.728 0 0 0 1.28 8 6.728 6.728 0 0 0 8 14.72 6.728 6.728 0 0 0 14.72 8 6.728 6.728 0 0 0 8 1.28M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8" />
            <path d="M7.943 11.828L4.387 8.272l.905-.905L7.67 9.744l3.016-5.221 1.109.64z" />
          </g>
        </svg>
      );
    case ConnectorStatusEnum.OCCUPIED:
    case SessionStatusEnum.STARTED:
    case SessionStatusEnum.WAITINGTOSTART:
    case SessionStatusEnum.WAITINGTOSTOP:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path
            fill={colors[status]}
            fillRule="evenodd"
            d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM9.4 3L4 8.516h3.852L6.14 13 12 6.67H8.543L9.4 3z"
          />
        </svg>
      );
    case SessionStatusEnum.STOPPED:
    case SessionStatusEnum.FAILED:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fillRule="evenodd">
            <path fill={colors[status]} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
            <path fill="#FFF" d="M10.427 11.333l-5.76-5.76.905-.905 5.76 5.76z" />
            <path fill="#FFF" d="M5.573 11.333l-.905-.905 5.76-5.76.905.905z" />
          </g>
        </svg>
      );
    case ConnectorStatusEnum.UNAVAILABLE:
    case ConnectorStatusEnum.UNKNOWN:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill={colors[status]} fillRule="evenodd">
            <path d="M8 1.28A6.728 6.728 0 0 0 1.28 8 6.728 6.728 0 0 0 8 14.72 6.728 6.728 0 0 0 14.72 8 6.728 6.728 0 0 0 8 1.28M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8" />
            <path d="M10.427 11.333l-5.76-5.76.905-.905 5.76 5.76z" />
            <path d="M5.573 11.333l-.905-.905 5.76-5.76.905.905z" />
          </g>
        </svg>
      );
    default:
      return <div />;
  }
};

export const ChargingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
    <path
      fill="#f5e150"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM9.4 3L4 8.516h3.852L6.14 13 12 6.67H8.543L9.4 3z"
    />
  </svg>
);
