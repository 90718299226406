import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { reducer, State } from './reducer';
import rest, { RestState } from './rest/reducer';
import { restifyAction } from './rest/reducer';
import * as actions from './actions';
import epics from './epics';

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose
    : compose;

const epicMiddleware = createEpicMiddleware();

const reducers = combineReducers({
  state: reducer,
  rest,
});

export interface RootState {
  state: State;
  rest: RestState;
}

export const store = createStore(reducers as any, composeEnhancers(applyMiddleware(epicMiddleware)));
export default store;

epicMiddleware.run(epics);

restifyAction(actions.fetchChargePoints);
restifyAction(actions.fetchSession);
restifyAction(actions.fetchReservationAmount);
