import React from 'react';
import { Button, ButtonProps, colors } from '@plugsurfing/elemental-ui';

export const LinkButton = React.memo((props: Omit<ButtonProps, 'disabled'>) => (
  <Button
    color={colors.cushyBlue}
    textColor={colors.fullBlack}
    marginTop="1em"
    paddingHorizontal="1em"
    status="plain"
    {...props}
  />
));
