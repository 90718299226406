import { RootState } from '../';
import { createSelector } from 'reselect';

export function selectLoadingAndError(type: string) {
  return createSelector(
    ({ rest }: RootState) => rest[type],
    loadingOrError => {
      switch (typeof loadingOrError) {
        case 'undefined':
          return { loading: false, error: undefined };
        case 'boolean':
          return {
            loading: loadingOrError,
            error: undefined,
          };
        default:
          return {
            loading: false,
            error: loadingOrError!,
          };
      }
    },
  );
}
