import { createSelector } from 'reselect';
import { State } from './reducer';
import { selectLoadingAndError } from './rest/selectors';
import * as actions from './actions';
import i18n from 'i18n';

export const selectConnectors = () =>
  createSelector(
    ({ state }: { state: State }) => state.connectors,
    selectLoadingAndError(actions.fetchChargePoints.type),
    (data, { loading, error }) => ({
      data,
      loading,
      error,
    }),
  );

export const selectSession = () =>
  createSelector(
    ({ state }: { state: State }) => state.session,
    selectLoadingAndError(actions.fetchSession.type),
    (data, { loading, error }) => ({
      data,
      loading,
      error,
    }),
  );

export const selectSelectedConnector = ({ state }: { state: State }) =>
  state.connectors?.find((connector) => state.selectedConnectorId === connector.id);

export const selectUserCode = ({ state }: { state: State }) => state.userCode;

export const selectCpoId = ({ state }: { state: State }) => state.cpoId;

export const selectReservationAmount = ({ state }: { state: State }) => state.reservationAmount;

export const selectPaymentRequest = ({ state }: { state: State }) =>
  state.availablePaymentOptions.walletPayment.paymentRequest;

export const selectIfAvailableWalletPaymentM = ({ state }: { state: State }) =>
  state.availablePaymentOptions.walletPayment.paymentRequest;

export const selectPaymentOptionsRadio = ({ state }: { state: State }) => {
  const a = state.availablePaymentOptions.paymentOptions.map((po) => ({
    label: i18n.t(po),
    value: po,
  }));
  return a;
};
