import React from 'react';
import { useField } from 'formik';
import { InputField, InputFieldProps } from '@plugsurfing/elemental-ui';

export const TextInput = React.memo((props: InputFieldProps) => {
  const [field, meta] = useField(props as any);
  return (
    <InputField
      {...field}
      {...props}
      width="100%"
      marginVertical="1em"
      error={meta.touched && !!meta.error}
      errorMessage={meta.error}
    />
  );
});
