import React, { useEffect } from 'react';
import { useField } from 'formik';
import {
  RadioButtonGroup as ElementalRadioButtonGroup,
  RadioButtonGroupProps,
  ContentText,
  ErrorMessage,
  useEventTargetValue,
} from '@plugsurfing/elemental-ui';

const style = {
  marginTop: '1rem',
  marginBottom: '1rem',
};

export const RadioButtonGroup = React.memo((props: RadioButtonGroupProps) => {
  const [value, handleChange] = useEventTargetValue(props.items[0].value);
  const [field, meta, helpers] = useField(props as any);

  useEffect(() => {
    helpers.setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <ElementalRadioButtonGroup width="100%" {...field} {...props} onChange={handleChange} style={style} />
      <ErrorMessage active={meta.touched && !!meta.error}>
        <ContentText>{meta.error}</ContentText>
      </ErrorMessage>
    </>
  );
});
