import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import FallbackTranslations from './locales/fallbacks';

const supportedLngs: string[] = process.env.REACT_APP_ENABLED_LANGUAGES!.split(',');

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS: 'Default',
    ns: ['Default'],
    fallbackLng: 'en',
    fallbackNS: 'Fallback',
    resources: FallbackTranslations,
    supportedLngs,
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === 'development',
    react: {
      useSuspense: false,
    },
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      referenceLng: 'en',
      private: true,
    },
    partialBundledLanguages: true,
  });

export default i18next;
