export default {
  "CREDIT_CARD": "Carte bancaire",
  "backToStart": "Retour\n",
  "chargePointLabel": "Identifiant de la zone",
  "chargerAvailable": "Le chargeur est gratuit\n",
  "chargingCompleted": "Nous vous remercions de la recharge",
  "chargingSession": "Le montant sera réservé afin d'initier la charge.",
  "choosePaymentDescription": "Veuillez sélectionner votre moyen de paiement: ",
  "connector": "N° du connecteur",
  "connectorStatusEnum": {
    "AVAILABLE": "Libre",
    "NO_DATA": "Indisponible",
    "OCCUPIED": "Occupé",
    "OUT_OF_ORDER": "Hors Service",
    "RESERVED": "Réservé",
    "UNAVAILABLE": "Indisponible",
    "UNKNOWN": "Indisponible"
  },
  "connectorType": "Type de connecteur / prise ",
  "connectorTypeEnum": {
    "CCS": "Prise CCS Combo",
    "CHADEMO": "Prise CHAdeMO",
    "CHADEMO_AND_CCS": "Prise CHAdeMO et CSS Combo",
    "DOMESTIC_I": "Prise EF",
    "IEC_62196_T1": "Prise Type 1",
    "IEC_62196_T1_COMBO": "Prise CCS Combo",
    "IEC_62196_T2": "Prise Type 2",
    "IEC_62196_T2_COMBO": "Prise CCS Combo",
    "IEC_62196_T3A": "Prise Type 3",
    "IEC_62196_T3C": "Prise Type 3",
    "SCHUKO": "Prise EF",
    "TYPE1": "Prise Type 1",
    "TYPE1_CABLE": "Prise Type 1",
    "TYPE2": "Prise Type 2",
    "TYPE2_AND_SCHUKO": "Type 2 et EF",
    "TYPE2_CABLE": "Prise Type 2",
    "TYPE3A": "Prise Type 3",
    "TYPE3A_AND_TYPE2": "Prise TYPE 2 et Prise TYPE 3",
    "TYPE3C_AND_SCHUKO": "TYPE 3 et DOMESTIQUE",
    "TYPE3C_AND_TYPE2": "Prise TYPE 2 et Prise TYPE 3",
    "UNKNOWN": "Prise inconnue"
  },
  "domainCancellationWarning": "Ce domaine sera supprimé le 01-juin-2023. Pour continuer à utiliser ce portail, veuillez migrer vers :",
  "duration": "Durée",
  "evseId": "Exemple : FR*A123*E12345*001*1*1",
  "evseIdDescription": "L'identifiant du point de charge est indiqué sur l'autocollant la borne. ",
  "evseIdNotFound": "Aucune borne trouvée",
  "evseIdNotSupported": "Chargeur non pris en charge",
  "failedToStartCharging": "Début de la charge échouée",
  "findChargerByEvseId": "Scannez le QR Code pour lancer la charge.\nSinon, saisissez l'identifiant du point de charge. ",
  "freeOfCharge": "Gratuit",
  "generatingReceipt": "Votre reçu arrive...",
  "googleRecaptchaMessage": "Ce site est protégé par reCAPTCHA  <1>Principes de confidentialités</1> et <3>Conditions de service</3> de Google s'appliquent.",
  "googleRecaptchaTerms": "Ce site est protégé par reCAPTCHA et les <1>Principes de confidentialité</1> et <2>Conditions de service</2> de Google s'appliquent.",
  "initiatingChargingSessionMessage": "Assurez-vous d'avoir connecté votre câble de recharge à votre véhicule et à la borne de recharge.",
  "invalidEvseIdParam": "l'EVSE ID n'est ps valide:  {{evseId}}",
  "mustBeAccepted": "Doit être accepté",
  "mustBeValidEvseId": "Identifiant invalide",
  "mustBeValidEvseIdShort": "EVSE ID invalide.",
  "noChargingSessionFound": "Échoue à trouver la session de recharge",
  "nonRegisteredUser": "Paiement par QR Code",
  "occupiedInfo": "Cette borne est actuellement occupée. Si vous êtes le conducteur du véhicule en train de charger, essayez de terminer la session de charge depuis le véhicule, ce qui devrait arrêter la session de charge et libérer le câble.",
  "paymentDescription": "Afin de débuter une session de charge, merci de bien vouloir rentrer vos coordonnées bancaires",
  "paymentHeading": "Informations de paiement",
  "price": "Tarif",
  "privacyPolicyText": "J'accepte la <1>Politique de Confidentialité</1>",
  "reserveAmountDescription": "Le montant affiché sera réservé sur votre moyen de paiement afin de s'assurer que les fonds sont suffisants. Cela permettra de lancer la session de charge. Il ne sera pas facturé. Le montant facturé sera celui calculé suivant le tarif appliqué.",
  "restoreSessionError": "Impossible de retrouver votre session de recharge. {{error}}",
  "search": "Rechercher",
  "select": "Sélectionner",
  "sendingStartRequest": "Démarrage de la session envoyé ...",
  "sessionFailed": "Borne de recharge ne répond pas. {{error}}",
  "sessionStatusEnum": {
    "COMPLETE": "Session de recharge finalisée",
    "CREATED": "Session de recharge crée",
    "FAILED": "Session de recharge échouée",
    "STARTED": "En charge",
    "STOPPED": "Session stoppée",
    "WAITING_TO_START": "En attente du démarrage",
    "WAITING_TO_STOP": "En attente de la finalisation"
  },
  "startCharging": "Débuter une session de recharge",
  "stopCharging": "Fin de recharge",
  "stopError": "Échec de l'envoi de la demande d'arrêt. {{error}}",
  "stripeElementsError": "Échec de la collecte des données de la carte bancaire.",
  "termsError": "Échec du chargement des termes. {{error}}",
  "termsText": "J'accepte les <1>CGUs</1>",
  "totalCost": "Coût TOTAL",
  "tryAgain": "Essayer à nouveau",
  "unknownError": "Un problème est survenu",
  "viewReceipt": "Votre reçu",
  "walletInfoDescription": "Confirmez votre paiement et lancez votre recharge.",
  "welcome": "Bienvenue,",
  "welcomeDescription": "Démarrez votre session de recharge, sans inscription."
};
