import React, { useState, useCallback } from 'react';
import { ErrorMessage, ContentText, colors } from '@plugsurfing/elemental-ui';
import {
  CardNumberElementComponent,
  CardExpiryElementComponent,
  CardCvcElementComponent,
} from '@stripe/react-stripe-js';

const style = {
  base: {
    fontSize: '1.125rem',
    fontFamily: 'FortumSans, arial, sans-serif',
    '::placeholder': {
      color: colors.fullBlack,
    },
  },
  invalid: {
    color: colors.fullBlack,
  },
};

export interface StripeInputProps {
  StripeElement: CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent;
  onValid(valid: boolean): void;
}

export const StripeInput = React.memo(({ StripeElement, onValid }: StripeInputProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [focused, setFocused] = useState<boolean>(false);

  const handleOnChange = useCallback(
    (event: any) => {
      if (event.complete) {
        setError(undefined);
        onValid(true);
      } else {
        onValid(false);
      }
      if (event.error) {
        setError(event.error.message);
      }
    },
    [setError, onValid],
  );

  const handleOnFocus = () => setFocused(true);

  const handleOnBlur = () => setFocused(false);

  return (
    <>
      <StripeElement
        className="stripe-element"
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        options={{ style, showIcon: true }}
      />
      <ErrorMessage active={!!error && !focused}>
        <ContentText>{error}</ContentText>
      </ErrorMessage>
    </>
  );
});
