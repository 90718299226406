export interface Configuration {
  CD_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  STRIPE_API_KEY: string;
  APP_ENV: 'test' | 'stage' | 'production';
}

export const configurationTest: Configuration = {
  CD_URL: 'https://cdc-api-test.plugsurfing.com/',
  GOOGLE_RECAPTCHA_SITE_KEY: '6Lf92OEUAAAAABxu8X-5ioZpz_WVilpx3D77sKdg',
  STRIPE_API_KEY: 'pk_test_CghxhfKDibnsENnYPoxMcQEg',
  APP_ENV: 'test',
};

export const configurationStage: Configuration = {
  CD_URL: 'https://cdc-api-stage.plugsurfing.com/',
  GOOGLE_RECAPTCHA_SITE_KEY: '6LemauQUAAAAAJeCBZepj2x5aaNaAFyWtfKnb1go',
  STRIPE_API_KEY: 'pk_test_pBuwN9LJYP7ljuQLtXjFY7pH00k4Won13m',
  APP_ENV: 'stage',
};

export const configurationProd: Configuration = {
  CD_URL: 'https://cdc-api.plugsurfing.com/',
  GOOGLE_RECAPTCHA_SITE_KEY: '6LfJ5v4UAAAAAJNgZh-9oLx3KSrkXm-3lmQfLEL7',
  STRIPE_API_KEY: 'pk_live_vi2dwZZFmE5ZpJ2Xin8pnaO0',
  APP_ENV: 'production',
};
